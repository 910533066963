<template>
	<div>
		<!-- audio -->
		<audio id="timeLeftNocie" class="dn" ref="audioPlayer">
			<source src="../assets/image/little-bell-14606.mp3" type="audio/mpeg">
		</audio>
		<!-- loading -->
		<div id="loading" class="flex-rc-cc dn"><img src="../assets/image/loading.png" alt="" /></div>
		<!-- alert -->
		<div id="calert" class="flex-rc-cc dn">
			<div class="calert-container">
				<h4 class="calert-title" style="text-align: center; padding: 20px 20px 0;font-size: 1.2em;"></h4>
				<div class="calert-text"> </div>
				<div class="calert-btn" @click="hide_calert">OK</div>
			</div>
		</div>
		<!-- page0 -->
		<div id="page-0" class="flex-column" v-if="page == 0">
			<div class="page0-header">Form Password</div>
			<div class="page0 flex-column flex-fill">
				<div class="page0-title">Please enter password to open the form</div>
				<div class="flex-fill">
					<div class="page-box">
						<div class="page-box-container">
							<input class="page0-box-input" type="text" placeholder="password" v-model="password"
								autofocus />
						</div>
					</div>

					<div style="height: 30px;"></div>
					<div class="page-button shadow" :class="!password ? 'disable' : ''" @click="getInfo">Confirm</div>

					<!-- <div class="page-tips">
						<p>This is a timed form, the time it takes you to complete it will be recorded. Make sure you submit on time !</p>
						<p>If you submit late, your response will still be saved.</p>
					</div> -->
				</div>

				<div class="page-foot">
					<p>Made by <a href="https://pipiform.com" target="_blank">Forms for Google
							Drive</a></p>
				</div>
			</div>
		</div>
		<!-- page1 -->
		<div id="page-1" v-if="page == 1">
			<div class="page1 flex-column">
				<div class="flex-fill">
					<div class="page-box shadow">
						<div class="page-box-headLine bg-purple"></div>
						<div class="page-box-container">
							<div class="flex-rb">
								<div class="page1-box-title flex-fill">{{info.formName}}</div>
								<div class="page1-box-time">
									<p class="tr">time allowed</p>
									<p class="page1-box-time-num">{{getTimeText_type1(info.time)}}</p>
								</div>
								<img class="page1-box-clock" src="../assets/image/timer1_icon.png" alt="clock" />
							</div>
							<div class="page1-box-content">{{info.instruction}}</div>
						</div>
					</div>

					<div class="page-box shadow">
						<div class="page-box-headLine bg-purple"></div>
						<div class="page-box-container">
							<input class="page1-box-input" type="text" placeholder="your full name" v-model="name" />
						</div>
					</div>

					<div class="page-button shadow" :class="!name ? 'disable' : ''" @click="submit">Start</div>

					<div class="page-tips">
						<p>This is a timed form, the time it takes you to complete it will be recorded. Make sure you
							submit on time !</p>
						<p>If you submit late, your response will still be saved.</p>
					</div>
				</div>

				<div class="page-foot">
					<!-- <p>Made with <a href="">Timer for Google Forms</a></p>
					<p><a href="">Some music</a> to listen to while you work</p>
					<p>My friend's <a href="">new clip</a></p> -->

					<p>Made by <a href="https://pipiform.com" target="_blank">Forms for Google
							Drive</a></p>
				</div>
			</div>
		</div>

		<div id="page-2" v-else-if="page == 2">
			<div class="cover" @click="cover_click" v-if="timeSelectShow == 1"></div>
			<div class="page2">
				<div class="page2-time-box flex-rb-cc" v-if="!info.timerOff">
					<div class="page2-time-num" v-if="showRecord == 0">{{getTimeText_type2(totalTime-spandTime)}}</div>
					<div class="page2-time-process flex-fill" v-if="showRecord == 0">
						<div class="page2-time-process-active" :style="'width:' + (1-spandTime/totalTime)*100 + '%;'">
						</div>
					</div>
					<div class="page2-time-num flex-fill nowrap" v-if="showRecord == 1">Time recorded:
						{{getTimeText_type3(spandTime)}}</div>
					<div class="page2-time-finger flex-rc-cc">
						<img src="../assets/image/fingerprint.png" alt="" />
						<div class="page2-time-finger-name">{{info.fingerCode}}</div>
					</div>
					<div class="page2-time-clock" @click="showTimeSelect(timeSelectShow == 1 ? 0 : 1)">
						<img src="../assets/image/timer2_icon.png" alt="" />
					</div>

					<div class="page2-time-select-box shadow" v-if="timeSelectShow == 1">
						<div class="page2-time-select-head">
							<p class="page2-time-select-head-title">Alarms</p>
							<p class="page2-time-select-head-content">Tab must be focused</p>
						</div>
						<div class="page2-time-select-item flex-rb-cc" v-for="(item, index) in timeLeft_dis"
							:key="index" @click="toggleTimeLeft(index)">
							<div class="page2-time-select-item-text"
								:class="totalTime-spandTime < item.time ? 'disable' : ''">
								{{getTimeText_type1(item.time)}} left
							</div>
							<div class="page2-time-select-item-toggle">
								<img src="../assets/image/right-disable.png"
									v-if="item.isToggle && totalTime-spandTime < item.time" />
								<img src="../assets/image/right.png"
									v-if="item.isToggle && totalTime-spandTime >= item.time" />
								<img src="../assets/image/close.png" v-if="!item.isToggle" />
							</div>
						</div>
					</div>

				</div>

				<div class="page-container">
					<div class="ifame" :class="info.timerOff ? 'iframeFull' : ''">
						<iframe id="iframe" v-if="info.formId"
							:src="'https://docs.google.com/forms/d/' + info.formId + '/viewform?entry.' + info.entryId + '=' +info.fingerCode"
							width='100%' height='100%' frameborder='0' name="_blank"></iframe>
						<!-- <iframe src="https://jieshuo51.com/start/ZBL4G9" frameborder="0" scrolling="no" id="iframe" onload="adjustIframeHeight()"></iframe> -->
						<!-- <iframe src='https://blog.csdn.net/qq_34293842/article/details/81669955' width='100%' height='100%' frameborder='0' name="_blank" id="iframe" ></iframe> -->
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	import { formatTimestamp } from './ComFun'

	export default {
		name: 'mainPage',
		props: {
			msg: String
		},
		data: () => {
			return {
				// serverType: 'dev', // 测试环境
				serverType: 'pub',		// 生产环境
				// host: `${window.location.protocol}//${window.location.host}/`,
				host:"https://api.timer.pipiform.com",

				shortId: null,
				page: -1,
				info: {},
				name: "",
				password: "",

				totalTime: 0,
				spandTime: 0,
				showRecord: 0,
				Timer: null,
				successTimer: null,
				timeSelectShow: 0,
				timeLeft_dis: [{
						time: 120,
						isMention: false,
						isToggle: false,
					},
					{
						time: 300,
						isMention: false,
						isToggle: false,
					},
					{
						time: 900,
						isMention: false,
						isToggle: false,
					},
					{
						time: 1800,
						isMention: false,
						isToggle: false,
					},
					{
						time: 3600,
						isMention: false,
						isToggle: false,
					},
					{
						time: 7200,
						isMention: false,
						isToggle: false,
					},
				],
				hideTimeStamp: 0,
			}
		},
		mounted() {
			let that = this
			this.shortId = this.getShortId();

			if (this.serverType == 'dev') {
				this.host = "https://api.timer.pipiform.com";

				// this.shortId = "UdIQof"; // 进行中
				this.shortId = "O81Lz4"; // 有密码
				// this.shortId = "5yC7Zu"; // 关闭连接
				// this.shortId = "GCQnc8"; // 关闭timer
				// this.shortId = 'hvsj59';	// 测试
			}

			this.getInfo();

			document.addEventListener("visibilitychange", function() {
				if (that.page == 2 && that.totalTime > 0 && that.spandTime > 0 && that.showRecord == 0) {
					if (document.hidden === true) {
						// console.log('页面被挂起')
						// that.calert(`挂起时消耗时间：${that.spandTime}`)
						that.hideTimeStamp = new Date().getTime();
					} else {
						// console.log('页面由挂起被激活')
						// that.calert('页面由挂起被激活')
						var _t = Math.round((new Date().getTime() - that.hideTimeStamp) / 1000);
						that.spandTime += _t;
						if (that.spandTime >= that.totalTime) {
							that.spandTime = that.totalTime;
						}
					}
				}
			});
		},
		methods: {
			getShortId() {
				// let _list = window.location.href.split("/");
				// return _list[_list.length - 1];
				const url = window.location.href;
				const path = url.split('?')[0]; // 取 ? 之前的部分
				const lastSegment = path.split('/').pop(); // 取最后一个路径片段
				return lastSegment?.trim()
			},
			getInfo() {
				let that = this;
				showLoading();
				$.ajax({
					method: "GET",
					url: `${this.host}/timer/h5/info`,
					data: {
						shortId: this.shortId,
						password: this.password,
					},
					success: function(res) {
						// console.log(res)
						hideLoading();
						if (res.code == 0) {
							that.setInfo(res.data);
						} else {
							that.calert(res.msg);
						}
					}
				})
			},
			setInfo(data) {
				let that = this;

				// urlSwitchOff(是否关闭连接): 0=连接,1=没有连接,
				// passwordOn(是否有密码): 0=无密码,1=有密码,
				// status(时效): 0=未开始,1=正常,2=已结束
				// timerOff(是否无计时): 0=有计时,1=无计时,

				that.info = data;
				if (data == null) {
					// 已失效
					// showLoading();
					if(that.shortId.length != 6){
						let message = `The URL must end with exactly 6 characters, but your link ends with ${that.shortId}.`
						that.calert(message);
					}else{
						that.calert("No data found. Please check if the URL is valid.");
					}
					return;
				} else if (data.urlSwitchOff) {
					// 关闭连接
					showLoading();
					that.calert("The link is no longer valid.");
					return;
				} else if (data.passwordOn) {
					// 有密码
					that.page = 0;
				} else if (data.timerOff) {
					// 关闭timer
					that.page = 2;
					setTimeout(function() {
						that.addIframeListener();
						that.statistics();
					}, 1000);
				} else {
					// 正常：无密码，有计时
					that.page = 1;
				}
				hideLoading();

				if (data.status == 0) {
					// var time = new Date(data.startStamp);
					let message = formatTimestamp(data.startStamp)
					setTimeout(function() {
						// that.calert(`Starting time ${time}`);
						that.calert(message, "This form will start accepting reponses on");
					}, 100);
				} else if (data.status == 2) {
					setTimeout(function() {
						// that.calert("Form Closed");
						that.calert("This form is now closed and does not accept any more answers", "Form Closed");
					}, 100);
				}
			},
			// page1
			beforeSubmit() {
				if (this.info.status == 0) {
					// var time = new Date(this.info.startStamp);
					// this.calert(`Starting time ${time}`);
					let message = formatTimestamp(this.info.startStamp)
					this.calert(message, "This form will start accepting reponses on");
					return false;
				} else if (this.info.status == 2) {
					// this.calert("Form Closed");
					this.calert("This form is now closed and does not accept any more answers", "Form Closed");
					return false;
				} else if (!this.name) {
					return false;
				}
				return true;
			},
			submit() {
				let that = this;
				let _iscan = this.beforeSubmit();
				if (_iscan) {
					showLoading();
					$.ajax({
						method: "POST",
						url: `${this.host}/timer/h5/submit`,
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
						data: {
							fingerCode: this.info.fingerCode,
							respondent: this.name,
							formId: this.info.formId,
						},
						success: function(res) {
							if (res.code == 0) {
								that.totalTime = that.info.time;
								that.page = 2;
								that.statistics();
								setTimeout(function() {
									that.addIframeListener();
								}, 1000);
								setTimeout(function() {
									showLoading();
								}, 100);
								setTimeout(function() {
									hideLoading();
									that.startTimer();
								}, 1000);
							} else {
								that.calert(res.msg);
							}
						},
						complete: function() {
							hideLoading();
						}
					})
				}
			},
			// page2
			startTimer() {
				console.log("开始计时")
				let that = this;
				if (this.Timer) {
					clearInterval(this.Timer)
					this.Timer = null;
				}
				this.Timer = setInterval(function() {
					that.spandTime += 1;
					that.checkTimeLeftToast(that.totalTime - that.spandTime);
					if (that.spandTime >= that.totalTime) {
						// 超时
						// console.log("超时");
						clearInterval(that.Timer);
						that.Timer = null;
						// that.playTimeLeftNoice("Time is up");
						that.calert("Time is up");
					}
				}, 1000)
			},
			stopTimer() {
				console.log("结束计时");
				clearInterval(this.Timer);
				this.Timer = null;
			},
			showTimeSelect(show) {
				this.timeSelectShow = show
			},
			cover_click() {
				this.timeSelectShow = 0
			},
			toggleTimeLeft(i) {
				if (this.timeLeft_dis[i].time <= this.totalTime - this.spandTime) {
					this.timeLeft_dis[i].isToggle = this.timeLeft_dis[i].isToggle ? false : true;
				}
			},
			checkTimeLeftToast(time) {
				for (var i = this.timeLeft_dis.length - 1; i >= 0; i--) {
					if (this.timeLeft_dis[i].isToggle && !this.timeLeft_dis[i].isMention && this.timeLeft_dis[i].time >=
						time) {
						// console.log(`${this.timeLeft_dis[i].time}秒 提醒`);
						this.playTimeLeftNoice(`${this.getTimeText_type1(this.timeLeft_dis[i].time)} remaining`);
						this.timeLeft_dis[i].isMention = true;
						break;
					}
				}
			},
			addIframeListener() {
				let that = this;
				if (this.page == 2) {
					document.getElementById("iframe").onload = function() {
						that.successCheckTimer()
					}
				}
			},
			successCheckTimer() {
				let that = this;
				if (this.successTimer != null) {
					clearInterval(this.successTimer);
					this.successTimer = null;
				}
				var num = 0;
				this.successTimer = setInterval(function() {
					console.log("num: ", num);
					if (num < 3) {
						that.successCheck();
						num++;
					} else {
						clearInterval(that.successTimer);
						that.successTimer = null;
					}
				}, 1500);
			},
			successCheck() {
				console.log('successCheck')
				let that = this;
				$.ajax({
					method: "POST",
					url: `${this.host}/timer/h5/fingercode/success`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					data: {
						fingerCode: this.info.fingerCode,
					},
					success: function(res) {
						if (res.code == 0) {
							if (res.data == true) {
								// 检查完成
								clearInterval(that.successTimer);
								that.successTimer = null;
								that.stopTimer();
								that.showRecord = 1;
							}
						}
					},
					complete: function() {}
				})
			},
			// 公共
			getTimeText_type1(sec) {
				if (!sec) {
					return '';
				}
				if (sec <= 0) {
					return `0 sec`;
				} else if (sec < 60) {
					return `${sec} s`
				} else if (sec < 60 * 60) {
					let _min = Math.floor(sec / 60);
					return `${_min} min`;
				} else {
					let _hours = Math.floor(sec / 60 / 60);
					let _min = Math.floor((sec - _hours * 60 * 60) / 60);
					let text = "";
					if (_hours > 0) {
						text += `${_hours} ${_hours > 1 ? 'hours' : 'hour'}`;
					}
					if (_hours > 0 && _min > 0) {
						text += " ";
					}
					if (_min > 0) {
						text += `${_min} min`;
					}
					return text;
				}
			},
			getTimeText_type2(sec) {
				if (!sec) {
					return '';
				}
				if (sec <= 0) {
					return `0 sec`;
				} else if (sec < 60) {
					return `${sec} sec`
				} else if (sec < 60 * 60) {
					let _min = Math.floor(sec / 60);
					return `${_min} min`;
				} else {
					let _hours = Math.floor(sec / 60 / 60);
					let _min = Math.floor((sec - _hours * 60 * 60) / 60);
					let text = "";
					if (_hours > 0) {
						text += `${_hours} h`;
					}
					if (_hours > 0 && _min > 0) {
						text += " ";
					}
					if (_min > 0) {
						text += `${_min} min`;
					}
					return text;
				}
			},
			getTimeText_type3(sec) {
				if (!sec) {
					return '';
				}
				if (sec <= 0) {
					return `0 second`;
				} else if (sec < 60) {
					return `${sec} second${sec > 1 ? 's' : ''}`
				} else if (sec < 60 * 60) {
					let _min = Math.floor(sec / 60);
					let _sec = sec - _min * 60;
					let _text = `${_min} min`;
					if (_sec > 0) {
						_text += ` ${_sec} sec`;
					}
					return _text;
				} else {
					let _hours = Math.floor(sec / 60 / 60);
					let _min = Math.floor((sec - _hours * 60 * 60) / 60);
					let _sec = sec - _hours * 60 * 60 - _min * 60;
					let text = "";
					if (_hours > 0) {
						text += `${_hours} h`;
					}
					if (_hours > 0 && _min > 0) {
						text += " ";
					}
					if (_min > 0) {
						text += `${_min} m`;
					}
					if (_sec > 0 && _min > 0) {
						text += " ";
					}
					if (_sec > 0) {
						text += `${_sec} s`;
					}
					return text;
				}
			},
			playTimeLeftNoice(text) {
				let that = this;
				const audioPlayer = this.$refs.audioPlayer;
				audioPlayer.play();
				setTimeout(function() {
					that.calert(text);
				}, 100)
			},
			calert(text, title) {
				$("#calert").find(".calert-text").text(`${text}`);
				if (title){
					$("#calert").find(".calert-title").text(`${title}`);
				}
				$("#calert").removeClass('dn');
			},
			hide_calert() {
				$("#calert").addClass('dn');
			},
			statistics() {
				// this.info.statisticsOff = false;
				if (!this.info.statisticsOff) {
					// let script_str = '<scri' + 'pt defer data-domain="gformsfree.com/super" src="https://plausible.io/js/script.js"></sc' + 'ript>';
					// console.log("加载统计", script_str);
					// document.write(script_str);

					const script = document.createElement('script');
					$(script).attr("data-domain", "gformsfree.com/super");
					$(script).attr("defer", "true");
					script.src = 'https://plausible.io/js/script.js';
					script.onload = () => {
						// 文件加载后的回调函数
						console.log("统计")
					};
					document.head.appendChild(script);
				}
			},
		},
	}

	function showLoading() {
		$("#loading").removeClass("dn");
	}

	function hideLoading() {
		$("#loading").addClass("dn");
	}
</script>

<style scoped>
	@import '../assets/css/common.css';
</style>