
export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // 格式化日期
    const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
    };
    const formattedDate = date.toLocaleString('en-US', options);

    // 计算剩余时间
    const now = new Date();
    const diffTime = date - now;

    let timeString = '';
    if (diffTime < 0) {
        timeString = '(already passed)';
    } else {
        const diffYears = date.getFullYear() - now.getFullYear();
        const diffMonths = date.getMonth() - now.getMonth() + (diffYears * 12);
        const diffDays = date.getDate() - now.getDate();
        const diffHours = date.getHours() - now.getHours();
        const diffMinutes = date.getMinutes() - now.getMinutes();

        if (diffYears > 0) {
            timeString = `(in ${diffYears} year${diffYears > 1 ? 's' : ''})`;
        } else if (diffMonths > 0) {
            timeString = `(in ${diffMonths} month${diffMonths > 1 ? 's' : ''})`;
        } else if (diffDays > 0) {
            timeString = `(in ${diffDays} day${diffDays > 1 ? 's' : ''})`;
        } else if (diffHours > 0) {
            timeString = `(in ${diffHours} hour${diffHours > 1 ? 's' : ''})`;
        } else if (diffMinutes > 0) {
            timeString = `(in ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''})`;
        } else {
            timeString = '(less than a minute)';
        }
    }

    return `${formattedDate}\n${timeString}`;
}
